<template>
    <div class="review">
        <basic-container>
            <el-container>
                <div>
                    <div class="Title">{{$t('DownloadPDF')}}</div>
                </div>
                <el-main style="overflow: hidden; padding-bottom:10px !important;">
                    <div class="topInfoReview">
                        <el-row>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('Supplier') }}:</span>
                                <span class="fontCont">{{ formData.supplierVal }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('PO') }}:</span>
                                <span class="fontCont">{{ formData.poVal }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('At') }}:</span>
                                <span class="fontCont">{{ formData.OrderDateVal }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('DEGREE OF COMPLETION') }}:</span>
                                <span class="fontCont">{{ formData.Speed }}</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('Brand') }}:</span>
                                <span class="fontCont">{{ formData.BrandVal }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('ACDate') }}:</span>
                                <span class="fontCont">{{ formData.ACDateVal }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('SEASON') }}:</span>
                                <span class="fontCont">{{ formData.SeasonVal }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('BUSINESS SERIAL NUMBER INTERNAL') }}:</span>
                                <span class="fontCont">{{ formData.BusinessNoInVal }}</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;"
                                     >{{ $t('MASTER STYLE NUMBER') }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('Product Name') }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('COLOR') }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span style="margin-right:10px;font-weight:700;font-size:14px;">{{ $t('QUANTITY') }}</span>
                            </el-col>

                        </el-row>
                        <el-row v-for="(copyitem,index) in InfoTopCopy" v-bind:key="index">
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span class="fontCont">{{ copyitem.Code }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span class="fontCont">{{ copyitem.ProductName }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span class="fontCont">{{ copyitem.Color }}</span>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6" class="colInfo">
                                <span class="fontCont">{{ copyitem.Number }}/{{ copyitem.UnitId }}</span>
                            </el-col>
                        </el-row>
                        <!--<el-row>
                            <el-col :sm="24" :md="12" :lg="6" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-coin"></i>{{$t('POProductCode')}} : {{formData.CodeVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="6" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-brush"></i>{{$t('Product Name'')}} : {{formData.ProductNameVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="6" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-brush"></i>{{$t('COLOR')}} : {{formData.ColorVal}}</span>
                            </el-col>
                            <el-col :sm="24" :md="12" :lg="6" style="display:flex;margin-bottom:20px;">
                                <span class="spanRight"><i class="el-icon-shopping-cart-full"></i>{{$t('QUANTITY')}} : {{formData.NumberVal}}/{{formData.UnitNameVal}}</span>
                                <span class="spanLeft"></span>
                            </el-col>
                        </el-row>-->
                    </div>
                </el-main>
                <el-main style="overflow: hidden; padding-bottom: 10px !important;">
                    <div class="InfoReview">
                        <el-row>
                            <el-col :sm="24" :md="2" :lg="2" style="padding-left: 5px; margin-top: 4px; ">
                                <span class="spanRight"> {{pdfPages}} {{$t('PAGES')}}</span>
                            </el-col>
                            <el-col :sm="24" :md="3" :lg="3"  style="margin-top: 4px; ">
                                <span class="spanRight">{{pdfSize}} MB</span>
                            </el-col>
                            <!--<el-col :sm="24" :md="6" :lg="6">
        <span class="spanRight">{{$t('UNIQUE CODE')}} : {{pdfName}}</span>
    </el-col>-->
                            <el-col :sm="24" :md="5" :lg="5"  style="margin-top: 4px; ">
                                <span class="spanRight">{{$t('CREATOR')}} : {{pdfUersName}}</span>
                            </el-col>
                            <el-col :sm="24" :md="10" :lg="10" style="margin-top: 4px; ">
                                <span class="spanRight">{{$t('CREATION TIME')}} : {{transformDate(pdfDt)}}</span>
                                <span style="margin-left: 10px !important; color: #ffd600;"  v-if="isNeedRegenerate">{{$t('Please regenerate the PDF. The declaration data has been modified')}}</span>
                            </el-col>
                            <el-col :sm="24" :md="1" :lg="1" style="text-align: right; font-weight: 700; margin-top: 4px; ">
                                <span class="spanRight"> {{$t('REGENERATE')}} </span>
                            </el-col>
                            <el-col :sm="24" :md="3" :lg="3" style="text-align: right; padding-right: 8px; ">
                                <el-button type="primary" icon="el-icon-receiving" size="mini" :title="$t('PdfGenerationList')" plain @click="PdfGenerationList"> {{$t('PdfGenerationList')}}</el-button>
                                <el-button type="primary" size="mini" @click="regenerate" plain icon="el-icon-refresh">{{$t('Full content')}}</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </el-main>
                <el-main style="overflow: hidden; padding-bottom: 10px !important;">
                    <avue-crud ref="crud"
                               v-model="obj"
                               :option="setData.tableOpt"
                               :data="tableData"
                               :page.sync="page"
                               @current-change="currentChange"
                               style="margin-bottom: 20px !important;">
                    </avue-crud>
                </el-main>
                <el-main style="overflow: hidden; padding-bottom: 10px !important;">
                    <div class="InfoReview">
                        <el-row>
                            <el-col :sm="24" :md="19" :lg="19">
                            </el-col>
                            <el-col :sm="24" :md="1" :lg="1" style="text-align: right; font-weight: 700; margin-top: 4px; ">
                                <span > {{$t('DOWNLOAD')}}  </span> 
                            </el-col>
                            <el-col :sm="24" :md="4" :lg="4" style="text-align: right; padding-right: 8px; ">
                                <el-button type="primary" size="mini" @click="downloadSummaryFile" icon="el-icon-download">{{$t('Summary')}}</el-button>
                                <el-button type="primary" size="mini" @click="downloadFile" icon="el-icon-download">{{$t('Full content')}}</el-button>
                                <!--<el-button type="success" size="small" @click="toReview" v-if="pdfSize<30">{{$t('Preview')}}</el-button>-->
                            </el-col>
                        </el-row>
                    </div>
                </el-main>
                <el-footer style="height:850px !important;background: #eee;padding: inherit;margin-top: 20px;">
                    <el-row style="text-align: center;" v-if="isReview">
                        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
                        </el-col>
                        <el-col v-loading="loadedRatio == 0" :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
                            <div style="height: 760px;overflow-y: auto; margin: 20px 10px;">
                                <pdf @page-loaded="loadedRatio = $event"
                                     v-for="page in pageNum"
                                     :key="page"
                                     :page="page"
                                     :src="url"
                                     style="margin:0 0 10px 0;">
                                </pdf>
                            </div>

                        </el-col>
                        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
                        </el-col>
                    </el-row>
                    <el-row style="text-align: center; padding-top: 15px;" v-if="!isReview&&pdfSize<30">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <span style="margin-left: 10px;">{{$t('To preview the PDF, please click the preview button')}}</span>
                        </el-col>
                    </el-row>
                    <el-row style="text-align: center; padding-top: 15px;" v-if="pdfSize>=30">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <span style="margin-left: 10px;">{{$t('PDF over 30MB, please download preview')}}</span>
                        </el-col>
                    </el-row>
                </el-footer>
            </el-container>
        </basic-container>
    </div>
</template>
<style scoped>
    .fontCont {
        font-size: 0.65rem;
        color: #fff;
    }
    .colInfo {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
    }
    .dataicon i {
        display: none;
    }
    .InfoReview {
        background: #545579;
        padding: 8px 2px 2px;
        border-radius: 10px;
        color: #fff;
        width: 100%;
        font-size: 14px;
        float: left;
    }
    .pvhFactory .el-table th, .pvhFactory .el-table th, .pvhFactory .el-table tr {
        background-color: #F4F4F4 !important;
        padding: 0.9375rem 0;
    }
    .pvhFactory .avue-crud .el-table th * {
        color: #333333 !important;
    }
.topInfoReview i {
  color: #B2EBE9;
  margin-right: 10px;
  font-size: 20px;
}

.topInfoReview p {
  width: calc(100% - 160px);
}


.othChe .el-checkbox__label {
  color: #409eff !important;
}
.topInfoReview {
  background: linear-gradient(to right,#4A4A74,#212146);
  border-radius: 10px;
  padding: 20px 10px 10px;
  color: #fff;
  width: 100%;
  font-size: 14px;
  float: left;
}
</style>


<script>
    import { add, GetOrderPackage, GetSummaryPackage, DownPDFPassKey, List, addSummaryPackage} from "@/api/orderPackage";
    import { TracingUnifiedIsBool } from "@/api/unified";
    import pdf from 'vue-pdf'
    import { pvhApiBase } from "@/config/env";
    import { POInfo } from "@/api/purchaseOrd";
    import { getToken, getGrop } from '@/util/auth'

    export default {
        components: { pdf },
        data() {
            return {
                InfoTopCopy:[],
                formData: {
                    soVal: '', fromVal: '', POproductVal: '', batchVal: '', codeVal: '', numberVal: '', unitVal: '', odateVal: '', productVal: '', remarkVal: '', departVal: '',
                    OrderDateVal: '', supplierVal: '', poVal: '', BusinessNoInVal: '', BusinessNoOutVal: '', ProductNameVal: '', BatchVal: '', ColorVal: '', NumberVal: '', UnitNameVal: '', CodeVal: '',
                    IndexNumberVal: 0, Speed: "", ACDateVal: '', SeasonVal: '', BrandVal: ''
                },
                POProductCode: {},
                isReview: false,
                isFinish: "DRAFT_",
                pdfPages: 0,
                pdfSize: 0,
                pdfName: "",
                pdfUersName: "",
                pdfDt: "",
                isNeedRegenerate:false,
                activeName: 'first',                
                srcObject: '',
                operationType: 1,
                token: '',
                downUrl: '',
                obj: {},
                loadId: '',
                loadSummaryId: '',
                loadOrderId: '',
                tableData: [],
                sourceData: [],
                pdfBase64: '',
                filePath: '',
                pdf: '',
                url: '',
                //懒加载页数
                pageNum: 1,
                //总页数
                numPages: '',
                height: '',
                loadedRatio: 0,
                loading: true,
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize: 5
                },
                typeData: [
                    {
                        label: this.$t('OPERATIONTYPE1'),
                        value: 1
                    },
                    {
                        label: this.$t('OPERATIONTYPE2'),
                        value: 2
                    },
                    {
                        label: this.$t('OPERATIONTYPE3'),
                        value: 3
                    }
                    ,
                    {
                        label: this.$t('OPERATIONTYPE4'),
                        value: 4
                    }

                ]
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: false,
                        refreshBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        addBtn: false,
                        editBtn: false,
                        header: false,
                        menu: false,
                        labelPosition: 'top',
                        column: [
                            {
                                label: this.$t('OPERATION TIME'),
                                prop: 'CreateTime',
                                type: "date",
                                format: "yyyy-MM-dd HH:mm:ss",
                                valueFormat: "yyyy-MM-dd HH:mm:ss",
                                order: 1,
                            },
                            {
                                label: this.$t('OPERATOR'),
                                prop: 'CreateUserid',
                            },
                            {
                                label: this.$t('OPERATION TYPE'),
                                prop: 'Type',
                                type: "select",
                                filterable: true,
                                dicData: this.typeData,
                            },
                            {
                                label: this.$t('HASH CODE'),
                                prop: 'UniqueCode',
                            }
                        ]
                    }
                }
            }
        },
        activated () {
            this.getScroll()
        },
        mounted() {//实时监听滚动条的变化
            this.init();
            window.addEventListener('scroll', this.getScroll,true)
        },
        destroyed() {//注意：监听的函数要在关闭页面时销毁掉,不然会影响到其它页面
            window.removeEventListener('scroll', this.getScroll)
        },
        methods: {
            init() {
                //  this.token = this.S4();
                this.loadOrderId = this.$route.query.OrderId;
                this.topInit();
                this.getList();


            },
            currentChange(val) {
                this.page.currentPage = val
                this.getList();
            },
            async getList() {
                await List(this.loadId, this.page.currentPage, this.page.pageSize).then(res => {
                    this.tableData = res.data.OrderPackageOperationLogs;
                    this.page.total = res.data.TotalCount;
                    this.page.pageSize = res.data.PageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },
            toReview() {
                    this.isReview = true;
                    // 调用子组件的下载方法
                    var po_new = this.formData.poVal;
                    if (po_new != undefined) {
                        var pattern = new RegExp("[`~!@#$%^&-+=\\?\"|,;'\\[\\]·~！@#￥%……&*（）+=\\{\\}\\|《》？：“”【】、；‘'，。\\、\\-<>]"); //[]内输入你要过滤的字符
                        var rs = "";
                        for (var i = 0; i < po_new.length; i++) {
                            rs += po_new.substr(i, 1).replace(pattern, '');
                        }
                        po_new = rs;
                    }
                    else {
                        po_new = "";
                    }
                    var fileName = this.isFinish +"_Summary_"+ "GI_TR_" + po_new + "_" + this.formData.IndexNumberVal + ".pdf";
                    this.url = pvhApiBase + "Tracing/GetSummaryPdfPreviewFileStream?packageId=" + this.loadSummaryId + "&fileName=" + fileName ;
                    const headers = {
                        Authorization: 'Bearer ' + getToken(),
                        'corpId': getGrop()
                    }

                    this.url = pdf.createLoadingTask({
                        url: this.url,
                        httpHeaders: headers
                    });

                    this.url.promise.then(pdf => {
                        this.pdf = pdf
                        this.numPages = pdf.numPages;
                        //滚动加载
                        this.getScroll();
                    });
                    //新增操作记录
                    this.addSummaryLog(2);
            },
            handleClick() {
                if (this.activeName == "second") {
                    this.$router.push({ path: '/pvh_operation/pdfLog', query: { ID: this.loadId, OrderId: this.loadOrderId } })
                }
            },
            getScroll(e) {
                const scrollTop = e.target.scrollTop
                this.height = 500

                console.log(this.pageNum, this.height*(this.pageNum-1),scrollTop,this.height*(this.pageNum+1))
                if ((scrollTop > this.height*(this.pageNum-1))&&(scrollTop < this.height*(this.pageNum+1))) { //懒加载条件限制判断
                    if (this.pageNum < this.numPages) {//做一次判断
                        //达到条件后执行这个函数
                        this.pageNum += 1
                        // this.pdf.getPage(this.pageNum).then(page => {
                        //   console.log(page,'page')
                        // })
                        console.log('达到加载条件，执行加载');
                    }
                }
            },
            // 获取pdf页数
            getNumPages () {
                let loadingTask = pdf.createLoadingTask(this.url)
                loadingTask.promise.then(pdf => {
                    this.numPages = pdf.numPages
                }).catch(err => {
                    this.numPages = 0
                    console.error('pdf 加载失败', err)
                })
            },
            addLog(type) {
                let paramrow = {
                    orderPackageId: this.loadId,
                    type: type,
                    remark: "",
                    status: 0,
                    createUserid: '',
                    corporationId: ''
                };
                add(paramrow).then(() => {
                }).catch((erro) => { console.log(erro) });
            },
            addSummaryLog(type) {
                let paramrow = {
                    orderPackageId: this.loadSummaryId,
                    type: type,
                    remark: "",
                    status: 0,
                    createUserid: '',
                    corporationId: ''
                };
                add(paramrow).then(() => {
                }).catch((erro) => { console.log(erro) });
            },
            regenerate() {
                TracingUnifiedIsBool("PdfIsJustCreateExist", this.loadOrderId).then(res => {
                    var IsExist = res.data;
                    this.loadingTask = null;
                    if (!IsExist) {
                        this.$confirm(this.$t('Fifteen minutes ago, the file was generated. Continue build'), this.$t('Tips'), {
                            confirmButtonText: this.$t('Yes'),
                            cancelButtonText: this.$t('Cancel'),
                            type: "warning"
                        }).then(() => {
                            this.$router.push({ path: '/pvh_operation/PdfGenerationList', query: { OrderId: this.loadOrderId, Speed: this.formData.Speed } });
                        })
                    } else {
                        this.$router.push({ path: '/pvh_operation/PdfGenerationList', query: { OrderId: this.loadOrderId, Speed: this.formData.Speed } });
                    }
                })
            },
            PdfGenerationList() {
                this.$router.push({ path: '/pvh_operation/PdfGenerationList', query: { OrderId: '', Speed: '' } })
            },
            downloadSummaryFile() {
                //获取redis 
                    // 调用子组件的下载方法
                    var po_new = this.formData.poVal;
                    if (po_new != undefined) {
                        var pattern = new RegExp("[`~!@#$%^&-+=\\?\"|,;'\\[\\]·~！@#￥%……&*（）+=\\{\\}\\|《》？：“”【】、；‘'，。\\、\\-<>]"); //[]内输入你要过滤的字符
                        var rs = "";
                        for (var i = 0; i < po_new.length; i++) {
                            rs += po_new.substr(i, 1).replace(pattern, '');
                        }
                        po_new = rs;
                    }
                    else {
                        po_new = "";
                    }
                    var fileName = this.isFinish + "_Summary_"+ "GI_TR_" + po_new + "_" + this.formData.IndexNumberVal + ".pdf";
                    //新增操作记录
                    this.addSummaryLog(4);
                    this.downUrl = pvhApiBase + "Tracing/GetSummaryPdfPreviewFileStream?packageId=" + this.loadSummaryId + "&fileName=" + fileName;

                    //打开新页面
                    window.open(this.downUrl, '_blank');
               //   this.downloadPDF(this.downUrl, fileName);
            },
            downloadFile() {
                //获取redis 
                DownPDFPassKey(this.loadId).then(res => {
                    var passKey = res.data;
                    // 调用子组件的下载方法
                    var po_new = this.formData.poVal;
                    if (po_new != undefined) {
                        var pattern = new RegExp("[`~!@#$%^&-+=\\?\"|,;'\\[\\]·~！@#￥%……&*（）+=\\{\\}\\|《》？：“”【】、；‘'，。\\、\\-<>]"); //[]内输入你要过滤的字符
                        var rs = "";
                        for (var i = 0; i < po_new.length; i++) {
                            rs += po_new.substr(i, 1).replace(pattern, '');
                        }
                        po_new = rs;
                    }
                    else {
                        po_new = "";
                    }
                    var fileName = this.isFinish + "GI_TR_" + po_new + "_" + this.formData.IndexNumberVal + ".pdf";
                    //var eType = "";
                    //if (this.operationType == 1) {
                    //    eType = "readOnly";
                    //    fileName = this.$t('READ-ONLY RIGHTS');
                    //} else {
                    //    eType = "print";
                    //    fileName = this.$t('READ-WRITE PERMISSION');
                    //}
                    //新增操作记录
                    this.addLog(4);
                    this.downUrl = pvhApiBase + "Tracing/GetPdfPreviewFileStream?packageId=" + this.loadId + "&fileName=" + fileName + "&passKey=" + passKey;
                    //打开新页面
                    window.open(this.downUrl, '_blank');
                    //   this.downloadPDF(this.downUrl, fileName);
                })
            },
            S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
            },
            guid() {
                // return (this.S4() + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + "-" + this.S4() + this.S4() + this.S4());
                return ("_" + this.S4() + this.S4() + this.S4());
            },
            downloadPDF(url, fileName) {
                fetch(url).then(function (response) {
                    if (response.ok) {
                        return response.arrayBuffer()
                    }
                    throw new Error('Network response was not ok.')
                }).then(function (arraybuffer) {
                    let blob = new Blob([arraybuffer], {
                        type: `application/pdf;charset-UTF-8` // word文档为msword,pdf文档为pdf
                    })

        let objectURL = URL.createObjectURL(blob)

        let downEle = document.createElement('a')
        let fname = fileName // 下载文件的名字
        // let fname = `download` // 下载文件的名字
        downEle.href = objectURL
        downEle.setAttribute('download', fname)
        document.body.appendChild(downEle)
        downEle.click()
      }).catch(function (error) {
        console.log('There has been a problem with your fetch operation: ', error.message)
      })
    },
    async topInit() {
      await POInfo(this.loadOrderId, this.loadOrderId, "", "").then(res => {
        var o = res.data;
        this.formData.supplierVal = o.POInfo.VendorCorporationId;
        this.formData.batchVal = o.POInfoDetails.Batch;
        this.formData.poVal = o.POInfo.Po;
        this.formData.BusinessNoInVal = o.POInfoDetails.BusinessNoIn;
        this.formData.BusinessNoOutVal = o.POInfoDetails.BusinessNoOut;
        this.formData.ProductNameVal = o.POInfoDetails.ProductName;
        this.formData.CodeVal = o.POInfoDetails.Code;
        this.formData.BatchVal = o.POInfoDetails.Batch;
        this.formData.ColorVal = o.POInfoDetails.Color;
        this.formData.NumberVal = o.POInfoDetails.Number;
        this.formData.UnitNameVal = o.POInfoDetails.UnitId;
        this.formData.IndexNumberVal = o.POInfoDetails.IndexNumber+1;
        this.formData.OrderDateVal = o.POInfo.OrderDate !== '' ? o.POInfo.OrderDate.substr(0, 10) : '';
        this.formData.BrandVal = o.POInfo.Brand;
        this.formData.SeasonVal = o.POInfo.Season;
        this.formData.ACDateVal = o.POInfo.ACDate !== '' ? o.POInfo.ACDate.substr(0, 10) : '';
        this.formData.Speed = o.POInfoDetails.Speed;
        this.InfoTopCopy = o.ListTopPoDetailInfo;
        if (o.POInfoDetails.Speed == "100%" || o.POInfoDetails.Speed == "100.00%" || o.POInfoDetails.Speed == "100.0%") {
            this.isFinish = "";
         }
          TracingUnifiedIsBool("PdfIsNeedRegenerate", this.loadOrderId).then(res => {
              this.isNeedRegenerate = res.data;
          });
          //获取摘要PDF信息
          TracingUnifiedIsBool("SummaryPdfIsNeedRegenerate", this.loadOrderId).then(res => {
              var NewSummaryPdf = res.data;
              if (NewSummaryPdf) {
                  let paramrow = {
                      id: "",
                      orderId: this.loadOrderId,
                      path: "Pending",
                      size: 0,
                      remark: this.formData.Speed,
                      status: 0,
                      createUserid: '',
                      corporationId: '',
                      pDFType: "5",
                  };
                  addSummaryPackage(paramrow).then(res => {
                      this.loadSummaryId = res.data.result
                      //新增操作记录
                      this.addSummaryLog(1);
                      this.toReview();

                  }).catch((erro) => {
                      console.log(erro)
                  });
              }
              else {
                  GetSummaryPackage(this.loadOrderId).then(res => {
                      var o = res.data;
                      if (o.id != '') {
                          this.loadSummaryId = o.id;
                          this.toReview();
                      }
                  });
              }
          });
          if (this.isNeedRegenerate) {
          

          }
          GetOrderPackage(this.loadOrderId).then(res => {
              var o = res.data;
              this.pdfDt = o.createTime;
              this.pdfPages = o.pdfPages;
              this.pdfSize = o.size;
              this.pdfName = o.uniqueCode;
              this.pdfUersName = o.createUserid;
              if (o.status == 0) {
                  if (o.id != '') {
                      this.loadId = o.id;
                      console.log(this.loadId);
                  }
              }
              else {
                  //生成文件
                  this.$message({ showClose: true, message: this.$t('NoPreviewedPrinting'), type: "warning" });
              }
          })
      })
    },
    //标准时间格式转yyyy-MM-dd HH:mm:ss
    transformDate(date) {
      if (date) {
        let dt = new Date(date);
        return dt.getFullYear() + '-' +
            ((dt.getMonth() + 1) < 10 ? ('0' + (dt.getMonth() + 1)) : (dt.getMonth() + 1)) + '-' +
            (dt.getDate() < 10 ? ('0' + dt.getDate()) : dt.getDate()) + ' ' +
            (dt.getHours() < 10 ? ('0' + dt.getHours()) : dt.getHours()) + ':' +
            (dt.getMinutes() < 10 ? ('0' + dt.getMinutes()) : dt.getMinutes()) + ':' +
            (dt.getSeconds() < 10 ? ('0' + dt.getSeconds()) : dt.getSeconds())
      } else {
        return ''
      }
    },
  },
}
</script>
