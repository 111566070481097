var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review" },
    [
      _c(
        "basic-container",
        [
          _c(
            "el-container",
            [
              _c("div", [
                _c("div", { staticClass: "Title" }, [
                  _vm._v(_vm._s(_vm.$t("DownloadPDF")))
                ])
              ]),
              _c(
                "el-main",
                {
                  staticStyle: {
                    overflow: "hidden",
                    "padding-bottom": "10px !important"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "topInfoReview" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("Supplier")) + ":")]
                              ),
                              _c("span", { staticClass: "fontCont" }, [
                                _vm._v(_vm._s(_vm.formData.supplierVal))
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("PO")) + ":")]
                              ),
                              _c("span", { staticClass: "fontCont" }, [
                                _vm._v(_vm._s(_vm.formData.poVal))
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("At")) + ":")]
                              ),
                              _c("span", { staticClass: "fontCont" }, [
                                _vm._v(_vm._s(_vm.formData.OrderDateVal))
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("DEGREE OF COMPLETION")) + ":"
                                  )
                                ]
                              ),
                              _c("span", { staticClass: "fontCont" }, [
                                _vm._v(_vm._s(_vm.formData.Speed))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("Brand")) + ":")]
                              ),
                              _c("span", { staticClass: "fontCont" }, [
                                _vm._v(_vm._s(_vm.formData.BrandVal))
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("ACDate")) + ":")]
                              ),
                              _c("span", { staticClass: "fontCont" }, [
                                _vm._v(_vm._s(_vm.formData.ACDateVal))
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("SEASON")) + ":")]
                              ),
                              _c("span", { staticClass: "fontCont" }, [
                                _vm._v(_vm._s(_vm.formData.SeasonVal))
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("BUSINESS SERIAL NUMBER INTERNAL")
                                    ) + ":"
                                  )
                                ]
                              ),
                              _c("span", { staticClass: "fontCont" }, [
                                _vm._v(_vm._s(_vm.formData.BusinessNoInVal))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("MASTER STYLE NUMBER")))]
                              )
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("Product Name")))]
                              )
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("COLOR")))]
                              )
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "colInfo",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "margin-right": "10px",
                                    "font-weight": "700",
                                    "font-size": "14px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("QUANTITY")))]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.InfoTopCopy, function(copyitem, index) {
                        return _c(
                          "el-row",
                          { key: index },
                          [
                            _c(
                              "el-col",
                              {
                                staticClass: "colInfo",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", { staticClass: "fontCont" }, [
                                  _vm._v(_vm._s(copyitem.Code))
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "colInfo",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", { staticClass: "fontCont" }, [
                                  _vm._v(_vm._s(copyitem.ProductName))
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "colInfo",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", { staticClass: "fontCont" }, [
                                  _vm._v(_vm._s(copyitem.Color))
                                ])
                              ]
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "colInfo",
                                attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                              },
                              [
                                _c("span", { staticClass: "fontCont" }, [
                                  _vm._v(
                                    _vm._s(copyitem.Number) +
                                      "/" +
                                      _vm._s(copyitem.UnitId)
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ]
              ),
              _c(
                "el-main",
                {
                  staticStyle: {
                    overflow: "hidden",
                    "padding-bottom": "10px !important"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "InfoReview" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "padding-left": "5px",
                                "margin-top": "4px"
                              },
                              attrs: { sm: 24, md: 2, lg: 2 }
                            },
                            [
                              _c("span", { staticClass: "spanRight" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.pdfPages) +
                                    " " +
                                    _vm._s(_vm.$t("PAGES"))
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-top": "4px" },
                              attrs: { sm: 24, md: 3, lg: 3 }
                            },
                            [
                              _c("span", { staticClass: "spanRight" }, [
                                _vm._v(_vm._s(_vm.pdfSize) + " MB")
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-top": "4px" },
                              attrs: { sm: 24, md: 5, lg: 5 }
                            },
                            [
                              _c("span", { staticClass: "spanRight" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("CREATOR")) +
                                    " : " +
                                    _vm._s(_vm.pdfUersName)
                                )
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-top": "4px" },
                              attrs: { sm: 24, md: 10, lg: 10 }
                            },
                            [
                              _c("span", { staticClass: "spanRight" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("CREATION TIME")) +
                                    " : " +
                                    _vm._s(_vm.transformDate(_vm.pdfDt))
                                )
                              ]),
                              _vm.isNeedRegenerate
                                ? _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "10px !important",
                                        color: "#ffd600"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "Please regenerate the PDF. The declaration data has been modified"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "text-align": "right",
                                "font-weight": "700",
                                "margin-top": "4px"
                              },
                              attrs: { sm: 24, md: 1, lg: 1 }
                            },
                            [
                              _c("span", { staticClass: "spanRight" }, [
                                _vm._v(" " + _vm._s(_vm.$t("REGENERATE")) + " ")
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "text-align": "right",
                                "padding-right": "8px"
                              },
                              attrs: { sm: 24, md: 3, lg: 3 }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-receiving",
                                    size: "mini",
                                    title: _vm.$t("PdfGenerationList"),
                                    plain: ""
                                  },
                                  on: { click: _vm.PdfGenerationList }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("PdfGenerationList"))
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    plain: "",
                                    icon: "el-icon-refresh"
                                  },
                                  on: { click: _vm.regenerate }
                                },
                                [_vm._v(_vm._s(_vm.$t("Full content")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-main",
                {
                  staticStyle: {
                    overflow: "hidden",
                    "padding-bottom": "10px !important"
                  }
                },
                [
                  _c("avue-crud", {
                    ref: "crud",
                    staticStyle: { "margin-bottom": "20px !important" },
                    attrs: {
                      option: _vm.setData.tableOpt,
                      data: _vm.tableData,
                      page: _vm.page
                    },
                    on: {
                      "update:page": function($event) {
                        _vm.page = $event
                      },
                      "current-change": _vm.currentChange
                    },
                    model: {
                      value: _vm.obj,
                      callback: function($$v) {
                        _vm.obj = $$v
                      },
                      expression: "obj"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-main",
                {
                  staticStyle: {
                    overflow: "hidden",
                    "padding-bottom": "10px !important"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "InfoReview" },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { sm: 24, md: 19, lg: 19 } }),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "text-align": "right",
                                "font-weight": "700",
                                "margin-top": "4px"
                              },
                              attrs: { sm: 24, md: 1, lg: 1 }
                            },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t("DOWNLOAD")) + "  ")
                              ])
                            ]
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: {
                                "text-align": "right",
                                "padding-right": "8px"
                              },
                              attrs: { sm: 24, md: 4, lg: 4 }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    icon: "el-icon-download"
                                  },
                                  on: { click: _vm.downloadSummaryFile }
                                },
                                [_vm._v(_vm._s(_vm.$t("Summary")))]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "mini",
                                    icon: "el-icon-download"
                                  },
                                  on: { click: _vm.downloadFile }
                                },
                                [_vm._v(_vm._s(_vm.$t("Full content")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-footer",
                {
                  staticStyle: {
                    height: "850px !important",
                    background: "#eee",
                    padding: "inherit",
                    "margin-top": "20px"
                  }
                },
                [
                  _vm.isReview
                    ? _c(
                        "el-row",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("el-col", {
                            attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
                          }),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadedRatio == 0,
                                  expression: "loadedRatio == 0"
                                }
                              ],
                              attrs: { xs: 16, sm: 16, md: 16, lg: 16, xl: 16 }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "760px",
                                    "overflow-y": "auto",
                                    margin: "20px 10px"
                                  }
                                },
                                _vm._l(_vm.pageNum, function(page) {
                                  return _c("pdf", {
                                    key: page,
                                    staticStyle: { margin: "0 0 10px 0" },
                                    attrs: { page: page, src: _vm.url },
                                    on: {
                                      "page-loaded": function($event) {
                                        _vm.loadedRatio = $event
                                      }
                                    }
                                  })
                                }),
                                1
                              )
                            ]
                          ),
                          _c("el-col", {
                            attrs: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isReview && _vm.pdfSize < 30
                    ? _c(
                        "el-row",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "padding-top": "15px"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "To preview the PDF, please click the preview button"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.pdfSize >= 30
                    ? _c(
                        "el-row",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "padding-top": "15px"
                          }
                        },
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "PDF over 30MB, please download preview"
                                      )
                                    )
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }